import React, { useEffect, useRef, useState } from "react";
import loadable from "@loadable/component";
import { useScroll } from "framer-motion";

const Content = loadable(() => import("@organisms/Challenge"));
const StoriesOfChangeCarousel = loadable(() =>
  import("@organisms/StoriesOfChangeCarousel")
);

const StoriesOfChangeScroll = ({ stories }) => {
  const { stories: storiesList } = stories;

  const ref = useRef(null);
  const [unfold, setUnfold] = useState(0);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end end"],
  });

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange(v => {
      setUnfold(v > 0.15);
    });
    return () => {
      unsubscribe();
    };
  }, [ref.current]);

  return (
    <div className="relative mb-20 sm:mb-28">
      <div className="relative z-10 mb-20 border-b-3 border-white sm:mb-28 md:ml-14" />
      <Content {...stories} twoColumns lessBottomSpacing />
      <div className="sticky top-0 flex h-screen w-full items-center overflow-hidden md:-mt-20 md:ml-14">
        <StoriesOfChangeCarousel
          stories={storiesList}
          progress={scrollYProgress}
          unfold={unfold}
        />
      </div>
      <div
        ref={ref}
        className="pointer-events-none absolute left-0 right-0 h-[75vh] w-full"
      />
      <div className="min-h-[120vh]" />
    </div>
  );
};

export default StoriesOfChangeScroll;
